/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import { Radio, Checkbox } from '@components/Form';
import { FUEL_TYPES } from '@root/stores/calculatorStore';
import InfoLabel from '@components/InfoLabel';
import { FlexRow, InfoIcon } from '@styles/globalStyles';
import {
  GroupLabel,
  FormGrid,
  InfoTooltipBody,
} from '@containers/Home/components/PhevForm/style';
import { breakpoint } from '@styles/breakpoints';
import useMatchBreakpoint from '@root/hooks/useMatchBreakpoint';
import InfoTooltip from '@components/Tooltip/components/InfoTooltip';
import { Box } from 'rebass';

const PhevForm = ({
  calculatorStore: { phevForm, setPhevFormValue },
  carsStore: { activeCar, equivalents },
  commonsStore: { isIrelandSite },
}) => {
  const onInputValueChange = ({ target }) => {
    setPhevFormValue(target.name, target.value);
  };

  const onCheckboxValueChange = ({ target }) => {
    setPhevFormValue(target.name, target.checked);
  };

  let petrolEquivalent = null;
  let dieselEquivalent = null;

  if (equivalents?.petrol && equivalents?.diesel && activeCar) {
    petrolEquivalent = equivalents.petrol[activeCar.petrolEquivalentId];
    dieselEquivalent = equivalents.diesel[activeCar.dieselEquivalentId];
  }

  const isDesktop = useMatchBreakpoint(breakpoint.md);
  const isTablet = useMatchBreakpoint(breakpoint.tabletPortrait);
  const tooltipProps = {
    ...(isDesktop
      ? {
          placement: 'right',
          offset: [0, 15],
        }
      : {
          placement: 'bottom',
          offset: isTablet ? [0, 12] : [0, -20],
        }),
  };

  const taxSavingLabel = (
    <>
      <span>Include {isIrelandSite ? 'motor tax' : 'VED'} saving</span>
      <InfoTooltip
        {...tooltipProps}
        body={
          <InfoTooltipBody
            dangerouslySetInnerHTML={{
              __html: `If ${isIrelandSite ? 'motor' : 'vehicle'} tax ${
                isIrelandSite ? '' : '(VED saving) '
              }is selected, the results will include any vehicle tax savings. The savings 
              have been calculated using the tax rates effective from 
              ${isIrelandSite ? 'January 2025' : 'April 2025'}. Vehicle 
              tax rates are different in the first year when a new vehicle is registered 
              and in subsequent years. This means any vehicle tax savings will be different 
              in the first year than in subsequent years. Any vehicle tax savings in the 
              first year are shown in both the monthly and the first year’s savings. The 
              three-year savings calculation is the sum of year one, and years two and 
              three savings. Please note the monthly savings shown are only the monthly 
              savings in the first year and have been calculated by dividing the first 
              year’s savings by 12 to indicate the monthly equivalent savings. The 
              calculation assumes you pay the tax annually. If you choose to pay vehicle 
              tax monthly or 6 monthly, you will pay a surcharge which has not been 
              included in the savings shown.`,
            }}
          />
        }
      >
        <Box as="span" ml={3}>
          <InfoIcon />
        </Box>
      </InfoTooltip>
    </>
  );

  return (
    <FormGrid>
      <div>
        <GroupLabel>Fuel Type</GroupLabel>
        <FlexRow bp={breakpoint.md}>
          <Radio
            name="fuelType"
            onChange={onInputValueChange}
            active={phevForm.fuelType}
            value={FUEL_TYPES.PETROL}
            label={
              <InfoLabel
                label="Compare to petrol"
                tooltipValue={
                  petrolEquivalent?.name || 'Choose BMW model first'
                }
              />
            }
          />
          <Radio
            name="fuelType"
            onChange={onInputValueChange}
            active={phevForm.fuelType}
            value={FUEL_TYPES.DIESEL}
            label={
              <InfoLabel
                label="Compare to diesel"
                tooltipValue={
                  dieselEquivalent?.name || 'Choose BMW model first'
                }
              />
            }
          />
        </FlexRow>
      </div>
      <div>
        <GroupLabel>Vehicle Tax</GroupLabel>
        <Checkbox
          label={taxSavingLabel}
          onChange={onCheckboxValueChange}
          value="include_ved_saving"
          name="taxSaving"
          checked={phevForm.taxSaving}
        />
      </div>
    </FormGrid>
  );
};

PhevForm.propTypes = {
  carsStore: PropTypes.shape({
    activeCar: PropTypes.object,
    equivalents: PropTypes.object,
  }).isRequired,
  calculatorStore: PropTypes.shape({
    phevForm: PropTypes.object,
    setPhevFormValue: PropTypes.func,
  }).isRequired,
};

export default inject(
  'calculatorStore',
  'carsStore',
  'commonsStore',
)(observer(PhevForm));
